@import "../../assets/styles/variables.scss";

.delete-role-info {
    display: flex;
    margin-bottom: 24px;
}

.delete-role-details {
    font-weight: bold;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and(max-width: 576px) {
    .rodal-dialog-delete-role {
        .rodal-dialog {
            width: 310px !important;
            height: 400px !important;
        }
        .delete-role-buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .btn-projects {
                margin-bottom: 10px;
            }
        }
    }
}
