@import '../../assets/styles/variables';

.propose-label {
    font-size: 18px;
    font-weight: bold;
}

.proposed-amount-wrap {
    display: flex;
    align-items: center;
    justify-content: center;

    .proposed-amount {
        width: 100px;
        font-size: 18px;
        margin-right: 10px;
        text-align: center;
        font-weight: bold;
    }

    .proposed-amount-info {
        font-size: 18px;
        font-weight: bold;
    }
}