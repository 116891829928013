.jumbo-landing {
  background-image: url('/assets/img/jumbo-1.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position-x: center;
  margin-top: 35px;
  height: 345px;

  .jumbo-landing-content {
    display: none;
  }
}

.mobile-landing-content {
  text-align: center;

  .jumbo-landing-title {
    margin-top: 18px;
    font-size: 29px;
    line-height: 39px;
    font-weight: bold;
    color: var(--color-projects);
  }

  .jumbo-landing-subtitle {
    font-size: 18px;
    line-height: 24px;
    color: var(--color-projects);
    margin-top: 12px;
  }

  .jumbo-landing-actions {
    margin-top: 35px;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;
  }
}

.jumbo-landing-link {
  font-size: 18px;
  color: var(--color-headlines);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    font-weight: bold;
    cursor: pointer;
  }
}

.section-title {
  font-size: 27px;
  font-weight: bold;
  color: var(--color-headlines);
  text-align: center;
  margin-top: 50px;
  margin-bottom: 35px;
}

.section-subtitle {
  font-size: 18px;
  color: var(--color-projects);
  text-align: center;
  margin-top: -30px;
}

.section-what-we-offer {
  .ul-section {
    font-size: 18px;
    margin-bottom: 1.5rem;
  }

  .offer-col {
    margin-bottom: 1.5rem;
  }
}

.section-how-it-works {
  margin-top: -5px;
  margin-bottom: 75px;
  padding-left: 10%;
  padding-right: 10%;
}

.section-how-it-works-mb {
  margin-top: -5px;
  margin-bottom: 75px;
}

.section-challenges {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  .row {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
  }
  .challenges-who {
    font-size: 18px;
    color: var(--color-placeholder);
  }
  .challenges-txt {
    font-size: 18px;
    margin-bottom: 28px;
  }
  .challenges-img {
    text-align: center;
  }
}

.btn-jumbo {
  font-size: 18px;
  padding-left: 70px;
  padding-right: 70px;
}

.jumbo-getintouch {
  background-size: cover;
  background-color: var(--color-developers);
  height: 333px;
  display: flex;
  align-items: center;
  color: white;

  .jumbo-getintouch-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 34px;
  }

  .jumbo-getintouch-subtitle {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
  }
}

.jumbo-hired {
  background-image: url('/assets/img/jumbo-hired.webp');
  background-size: cover;
  height: 333px;
  display: flex;
  align-items: center;
  color: white;
  background-attachment: fixed;

  .jumbo-hired-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 34px;
  }

  .jumbo-hired-subtitle {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
  }
}

.jumbo-solution {
  background-image: url('/assets/img/solution-bg.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 495px;
  background-attachment: fixed;

  .solution-img {
  }

  .solution-txt {
    max-width: 418px;
    text-align: center;
  }

  .solution-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .solution-subtitle {
    font-size: 17px;
  }
}

.jumbo-finding-freelancer {
  background-color: transparent;
  height: 450px;

  .finding-freelancer-img {
  }

  .finding-freelancer-title {
    font-size: 24px;
    font-weight: bold;
    color: var(--color-developers);
  }

  .finding-freelancer-subtitle {
    font-size: 16px;
    font-weight: bold;
    color: var(--color-projects);
  }
}

.section-how-we-achieve-it {
  // padding-top: 30px;
  padding-bottom: 0px;
}

.title-20-bold {
  font-size: 20px;
  font-weight: bold;
  color: var(--color-headlines);
}

.text-18 {
  font-size: 18px;
  color: var(--color-text);
}

.jumbo-link {
  font-size: 18px;
  color: var(--color-developers);
  margin-top: 12px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:active {
    text-decoration: underline;
    cursor: pointer;
  }
}

.how-img {
  width: 135px;
}

.jumbo-joinus {
  background-image: url('/assets/img/join-contribute.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 930px;
  display: flex;
  align-items: center;
  margin-top: 30px;
  background-attachment: fixed;

  .jumbo-joinus-title {
    font-size: 32px;
    font-weight: bold;
    color: white;
  }
}

.join-us-img {
  width: 135px;
}

.jumbo-title-white {
  font-size: 36px;
  font-weight: bold;
  color: white;
}

.mt-85 {
  margin-top: 85px;
}

.mobile-center {
  text-align: center;
}

.landing-partner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 198px;
  height: 76px;
  .partner-img {
    max-width: 198px;
    max-height: 76px;
  }
}

.react-multi-carousel-track {
  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.paint-to-black {
  color: black;
}

.img-map:hover {
  cursor: pointer;
}

//access each div inside li for design if we need
/* li[data-index='0'] {
  padding-left: 20px;
  padding-right: 20px;
}

li[data-index='1'] {
  padding-left: 20px;
  padding-right: 20px;
}

li[data-index='2'] {
  padding-left: 20px;
  padding-right: 20px;
}

li[data-index='3'] {
  padding-left: 20px;
  padding-right: 20px;
} */

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .mobile-landing-content {
    .jumbo-landing-actions {
      :first-child {
        margin-right: 12px;
      }
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .mobile-landing-content {
    display: none;
  }
  .jumbo-landing {
    background-position-x: right;
    display: flex;
    align-items: center;
    height: 350px;

    .jumbo-landing-content {
      display: block;

      .jumbo-landing-title {
        margin-top: 0;
        font-size: 30px;
        line-height: 35px;
        font-weight: bold;
        color: var(--color-projects);
        max-width: 53%;
      }

      .jumbo-landing-subtitle {
        font-size: 16px;
        line-height: 22px;
        color: var(--color-projects);
        margin-top: 12px;
        max-width: 55%;
      }

      .jumbo-landing-actions {
        margin-top: 35px;
        margin-bottom: 16px;
        max-width: 50%;
        display: flex;
        flex-direction: row;
        :first-child {
          margin-bottom: 12px;
          margin-right: 12px;
        }
        .btn {
          margin-bottom: 12px;
        }
      }

      .jumbo-landing-link {
        font-size: 16px;
        color: var(--color-headlines);
        text-decoration: underline;
      }
    }
  }
  .join-us-desktop {
    display: block;
  }

  .section-title {
    font-size: 36px;
    margin-top: 75px;
    margin-bottom: 50px;
  }

  .section-subtitle {
    font-size: 20px;
    margin-top: -50px;
  }

  .jumbo-getintouch {
    height: 333px;

    .jumbo-getintouch-title {
      font-size: 55px;
      margin-bottom: 34px;
    }

    .jumbo-getintouch-subtitle {
      font-size: 20px;
      max-width: 69%;
    }
  }

  .jumbo-hired {
    height: 500px;

    .jumbo-hired-title {
      font-size: 55px;
      margin-bottom: 34px;
    }

    .jumbo-hired-subtitle {
      font-size: 20px;
      max-width: 69%;
    }
  }

  .section-challenges {
    .row {
      margin-bottom: 100px;
      display: flex;
      align-items: center;
    }
    .challenges-who {
      font-size: 18px;
      color: var(--color-placeholder);
      margin-bottom: 18px;
    }
    .challenges-txt {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 28px;
    }
  }

  .jumbo-solution {
    height: 455px;

    .solution-txt {
      max-width: 100%;
    }

    .solution-title {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 32px;
    }

    .solution-subtitle {
      font-size: 18px;
    }
  }

  .jumbo-finding-freelancer {
    height: 375px;

    .finding-freelancer-title {
      font-size: 32px;
    }

    .finding-freelancer-subtitle {
      font-size: 18px;
    }
  }

  .jumbo-joinus {
    height: 500px;
    margin-top: 60px;
    .jumbo-joinus-title {
      font-size: 36px;
    }
  }

  .tablet-left {
    text-align: left;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .jumbo-landing {
    height: 530px;

    .jumbo-landing-content {
      display: block;

      .jumbo-landing-title {
        font-size: 35px;
        line-height: 40px;
        font-weight: bold;
        color: var(--color-projects);
        max-width: 53%;
      }

      .jumbo-landing-subtitle {
        font-size: 18px;
        line-height: 24px;
        color: var(--color-projects);
        margin-top: 12px;
        max-width: 49%;
      }

      .jumbo-landing-actions {
        margin-top: 35px;
        margin-bottom: 50px;
        :first-child {
          margin-right: 12px;
        }
      }

      .jumbo-landing-link {
        font-size: 18px;
        color: var(--color-headlines);
        text-decoration: underline;
      }
    }
  }

  .jumbo-getintouch {
    .jumbo-getintouch-title {
      font-size: 62px;
    }
    .jumbo-getintouch-subtitle {
      max-width: 43%;
    }
  }

  .jumbo-hired {
    .jumbo-hired-title {
      font-size: 62px;
    }
    .jumbo-hired-subtitle {
      max-width: 43%;
    }
  }

  .section-challenges {
    .row {
      margin-bottom: 100px;
      display: flex;
      align-items: center;
    }
    .challenges-who {
      font-size: 18px;
      color: var(--color-placeholder);
      margin-bottom: 18px;
    }
    .challenges-txt {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 28px;
    }
  }

  .jumbo-finding-freelancer {
    height: 375px;

    .finding-freelancer-title {
      font-size: 32px;
    }

    .finding-freelancer-subtitle {
      font-size: 18px;
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .jumbo-landing {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    height: 635px;
    background-size: unset;

    .jumbo-landing-content {
      .jumbo-landing-title {
        font-size: 48px;
        line-height: 58px;
        font-weight: bold;
        color: var(--color-projects);
      }

      .jumbo-landing-subtitle {
        font-size: 20px;
        line-height: 25px;
        color: var(--color-projects);
        margin-top: 12px;
        max-width: 47%;
      }

      .jumbo-landing-actions {
        margin-top: 35px;
        margin-bottom: 90px;
      }

      .jumbo-landing-link {
        font-size: 18px;
        color: var(--color-headlines);
        text-decoration: underline;
      }
    }
  }

  .section-what-we-offer {
    .ul-section {
      font-size: 20px;
    }
  }

  .jumbo-solution {
    height: 520px;

    .solution-img {
      background-image: url('/assets/img/solution-for-all-3.png');
      background-position-x: right;
      background-repeat: no-repeat;
      background-size: contain;
      height: 420px;
      max-width: 1366px;
      margin-right: auto;
      margin-left: auto;
    } 

    .solution-txt {
      max-width: 450px;
      font-size: 20px;
      text-align: left;
    }
  }

  .jumbo-finding-freelancer {
    background-color: transparent;
    height: 444px;

    .finding-freelancer-img {
      background-image: url('/assets/img/finding-freelancer.png');
      background-position-x: left;
      background-repeat: no-repeat;
      background-size: contain;
      height: 410px;
      max-width: 1430px;
      margin-right: auto;
      margin-left: auto;
    }

    .finding-freelancer-title {
      font-size: 32px;
      font-weight: bold;
      color: var(--color-developers);
    }

    .finding-freelancer-subtitle {
      font-size: 18px;
      font-weight: bold;
      color: var(--color-projects);
    }
  }

  .jumbo-joinus {
    height: 500px;
    margin-top: 60px;
    .jumbo-joinus-title {
      font-size: 36px;
    }
  }
}

.countdown-title {
  margin: 0 auto;
  text-align: center;

  h2 {
    color: #ffffff;
    font-weight: normal;
    font-size: 2.5rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
  }
  @media all and (max-width: 768px) {
    h2 {
      font-size: 28px;
    }
  }
}

.countdown-cont {
  text-align: center;
  ul {
    padding: 0 !important;
    margin: 0 !important;
  }
  li {
    display: inline-block;
    font-size: 1.5em;
    list-style-type: none;
    padding: 1em;
    text-transform: uppercase;
  }

  li span {
    display: block;
    font-size: 4.5rem;
  }
  @media all and (max-width: 576px) {
    li {
      font-size: 17px;
    }

    li span {
      font-size: calc(3.375rem * var(--smaller));
    }
  }
}
