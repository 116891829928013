@import '../variables.scss';

.avatar-list {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.avatar-item {
    display: flex;
    margin-bottom: 12px;

    :first-child {
        margin-right: 12px;
    }

    :last-child {
        padding-top: 3px;
    }
    
}
