@import "../../../assets/styles/variables.scss";

.tag-listwrap {
  display: flex;
  flex-wrap: wrap;

  .tagtag {
    padding: 1px 15px;
    background-color: white;
    border-radius: 3px;
    color: $color-developers;
    border: 1px solid $color-developers;
    font-size: 14px;
    margin-right: 16px;
    margin-bottom: 16px;

    &:hover {
      cursor: pointer;
      background-color: $color-developers;
      color: white;
    }
  }
}

.ctagselected {
  background-color: $color-developers !important;
  color: white !important;
}

/* Styles for React-Tag-Input */
.ctags {
}

.ctagInput {
}

.ctagInputField {
  width: 500px;
  padding: 8px 15px;
  border-radius: 3px;
  border: 1px solid $color-placeholder;
  color: $color-placeholder;
  margin-top: 10px;
  font-size: 14px;
}

.cselected {
}

.ctag {
  display: none;
}

.cremove {
}

.csuggestions {
  position: absolute;
}

.csuggestions ul {
  list-style-type: none;
  padding: 0;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 100%;
}

.csuggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.csuggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.csuggestions ul li.cactiveSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.cactiveSuggestion {
}


/* Selected tags */
.selected-listwrap {
    display: flex;
    flex-wrap: wrap;

    .sel-tag {
        padding: 1px 15px;
        background-color: $color-developers-transparent-10;
        border-radius: 3px;
        color: $color-developers;
        // border: 1px solid $color-developers;
        font-size: 14px;
        margin-right: 16px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .sel-tag-label {
            display: block;
            opacity: 0.78;
        }

        .sel-tag-remove {
            display: block;
            opacity: 0.78;
            // margin-left: 9px;
            margin-right: -15px;
            padding: 2px 15px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}