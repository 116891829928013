/* 
    WorkNSkill Theme - Global variables

   Sass map contains a list of all Theme variables.
   A theme consists of a list of colors; backgrounds; borders; text styles and supporting variables.

*/


// Main/Basic Colors
// -----------------------------

$color-projects:    #1f3a86;
$color-developers:  #008dce;
$color-employers:   #ffc500;
$color-educationals:#fb7400;
$color-auditors:    #0f5ce4;
$color-competencies: #359b4f;
$color-headlines:   #131f3d;
$color-text:        #444444;
$color-placeholder: #afafaf;
$color-borders:     #dddddd;
$color-lighthover:  #fafafa;
$color-background:  #ffffff;

$color-danger:      #FF0000;
$color-success:     #23D59B;

// DRAFT
// PUBLISHED
// ACTIVE
// CANCELED
// COMPLETED
// IN_NEGOTIATION
// IN_PROGRESS
// SUBMITTED
// IN_AUDIT
// FAILED
// AUDITOR_FAILED
// AUDITOR_CONFIRMED
$color-status-draft:       $color-text;
$color-status-published:   $color-employers;
$color-status-negotiating: $color-educationals;
$color-status-active:      $color-success;
$color-status-in-progress: $color-success;
$color-status-submitted:    $color-developers;
$color-status-rejected:   $color-danger;
$color-status-failed:      $color-danger;
$color-status-audit-failed: $color-danger;
$color-status-audit-confirmed: $color-success;
$color-status-completed:   $color-projects;
$color-status-in-audit:    $color-auditors;

$color-level-1: #BC7DC7;
$color-level-2: #4CAFDC;
$color-level-3: #79D4A0;
$color-level-4: #FFD033;
$color-level-5: #FF9A43;
$color-level-6: #FF7C70;


$color-basic-100: $color-projects;
$color-basic-200: $color-developers;
$color-basic-300: $color-employers;
$color-basic-400: $color-educationals;
$color-basic-500: $color-auditors;
$color-basic-600: $color-headlines;
$color-basic-700: $color-text;
$color-basic-800: $color-placeholder;
$color-basic-900: $color-borders;
$color-basic-1000: $color-lighthover;
$color-basic-1100: $color-background;

$color-basic-transparent-100: rgba(31, 58, 134, 0.1);
$color-basic-transparent-200: rgba(0, 141, 206, 0.1);
$color-basic-transparent-300: rgba(255, 197, 0, 0.1);
$color-basic-transparent-400: rgba(251, 116, 0, 0.1);
$color-basic-transparent-500: rgba(15, 92, 228, 0.1);
$color-basic-transparent-600: rgba(19, 31, 61, 0.1); 
$color-basic-transparent-700: rgba(68, 68, 68, 0.1);
$color-basic-transparent-800: rgba(175, 175, 175, 0.1);
$color-basic-transparent-900: rgba(221, 221, 221, 0.1);
$color-basic-transparent-1000: rgba(250, 250, 250, 0.1);
$color-basic-transparent-1100: rgba(255, 255, 255, 0.1);

// Color DEVELOPERS transparency
$color-developers-transparent-10: #008DCE1A;


// Status colors: primary, success, info, warning, danger - for colored elements (buttons, etc)
// -----------------------------

 $color-primary-100: #C9FAFC;
 $color-primary-200: #95EFF9;
 $color-primary-300: #5FD7EF;
 $color-primary-400: #37B8DF;
 $color-primary-500: #008ECB; // used
 $color-primary-600: #006EAE;
 $color-primary-700: #005292;
 $color-primary-800: #003A75;
 $color-primary-900: #002A61;


 $color-primary-transparent-100: rgba(51, 102, 255, 0.08);
 $color-primary-transparent-200: rgba(51, 102, 255, 0.16);
 $color-primary-transparent-300: rgba(51, 102, 255, 0.24);
 $color-primary-transparent-400: rgba(51, 102, 255, 0.32);
 $color-primary-transparent-500: rgba(51, 102, 255, 0.4);
 $color-primary-transparent-600: rgba(51, 102, 255, 0.48);

 $color-success-100: #D2FCDE;
 $color-success-200: #A6FAC6;
 $color-success-300: #78F2B2;
 $color-success-400: #55E5A8;
 $color-success-500: #23D59B; // used
 $color-success-600: #19B793;
 $color-success-700: #119987;
 $color-success-800: #0B7B78;
 $color-success-900: #065F66;

 $color-success-transparent-100: rgba(0, 214, 143, 0.08);
 $color-success-transparent-200: rgba(0, 214, 143, 0.16);
 $color-success-transparent-300: rgba(0, 214, 143, 0.24);
 $color-success-transparent-400: rgba(0, 214, 143, 0.32);
 $color-success-transparent-500: rgba(0, 214, 143, 0.4);
 $color-success-transparent-600: rgba(0, 214, 143, 0.48);

 $color-info-100: #f2f8ff;
 $color-info-200: #c7e2ff;
 $color-info-300: #94cbff;
 $color-info-400: #42aaff;
 $color-info-500: #0095ff;
 $color-info-600: #006fd6;
 $color-info-700: #0057c2;
 $color-info-800: #0041a8;
 $color-info-900: #002885;

 $color-info-transparent-100: rgba(0, 149, 255, 0.08);
 $color-info-transparent-200: rgba(0, 149, 255, 0.16);
 $color-info-transparent-300: rgba(0, 149, 255, 0.24);
 $color-info-transparent-400: rgba(0, 149, 255, 0.32);
 $color-info-transparent-500: rgba(0, 149, 255, 0.4);
 $color-info-transparent-600: rgba(0, 149, 255, 0.48);

 $color-warning-100: #FFF7CC;
 $color-warning-200: #FFEE99;
 $color-warning-300: #FFE266;
 $color-warning-400: #FFD63F;
 $color-warning-500: #FFC300; // used
 $color-warning-600: #DBA200;
 $color-warning-700: #B78300;
 $color-warning-800: #936600;
 $color-warning-900: #7A5100;

 $color-warning-transparent-100: rgba(255, 170, 0, 0.08);
 $color-warning-transparent-200: rgba(255, 170, 0, 0.16);
 $color-warning-transparent-300: rgba(255, 170, 0, 0.24);
 $color-warning-transparent-400: rgba(255, 170, 0, 0.32);
 $color-warning-transparent-500: rgba(255, 170, 0, 0.4);
 $color-warning-transparent-600: rgba(255, 170, 0, 0.48);

 $color-danger-100: #FFDDCC;
 $color-danger-200: #FFB399;
 $color-danger-300: #FF8066;
 $color-danger-400: #FF503F;
 $color-danger-500: #FF0000; // used
 $color-danger-600: #DB0012;
 $color-danger-700: #B7001F;
 $color-danger-800: #930026;
 $color-danger-900: #7A002A;

 $color-danger-transparent-100: rgba(255, 61, 113, 0.08);
 $color-danger-transparent-200: rgba(255, 61, 113, 0.16);
 $color-danger-transparent-300: rgba(255, 61, 113, 0.24);
 $color-danger-transparent-400: rgba(255, 61, 113, 0.32);
 $color-danger-transparent-500: rgba(255, 61, 113, 0.4);
 $color-danger-transparent-600: rgba(255, 61, 113, 0.48);



/* Status colors states - focus, hover, default, active, disabled  */

$color-basic-focus: color-basic-400;
$color-basic-hover: color-basic-200;
$color-basic-default: color-basic-300;
$color-basic-active: color-basic-400;
$color-basic-disabled: color-basic-transparent-300;
$color-basic-focus-border: color-basic-500;
$color-basic-hover-border: color-basic-hover;
$color-basic-default-border: color-basic-default;
$color-basic-active-border: color-basic-active;
$color-basic-disabled-border: color-basic-disabled;

$color-basic-transparent-focus: color-basic-transparent-300;
$color-basic-transparent-hover: color-basic-transparent-200;
$color-basic-transparent-default: color-basic-transparent-100;
$color-basic-transparent-active: color-basic-transparent-300;
$color-basic-transparent-disabled: color-basic-transparent-200;
$color-basic-transparent-focus-border: color-basic-600;
$color-basic-transparent-hover-border: color-basic-600;
$color-basic-transparent-default-border: color-basic-600;
$color-basic-transparent-active-border: color-basic-600;
$color-basic-transparent-disabled-border: color-basic-transparent-300;

$color-primary-focus: $color-primary-600;
$color-primary-hover: $color-primary-400;
$color-primary-default: $color-primary-500;
$color-primary-active: $color-primary-600;
$color-primary-disabled: $color-basic-transparent-300;
$color-primary-focus-border: $color-primary-700;
$color-primary-hover-border: $color-primary-hover;
$color-primary-default-border: $color-primary-default;
$color-primary-active-border: $color-primary-active;
$color-primary-disabled-border: $color-primary-disabled;

$color-primary-transparent-focus: $color-primary-transparent-300;
$color-primary-transparent-hover: $color-primary-transparent-200;
$color-primary-transparent-default: $color-primary-transparent-100;
$color-primary-transparent-active: $color-primary-transparent-300;
$color-primary-transparent-disabled: $color-basic-transparent-200;
$color-primary-transparent-focus-border: $color-primary-500;
$color-primary-transparent-hover-border: $color-primary-500;
$color-primary-transparent-default-border: $color-primary-500;
$color-primary-transparent-active-border: $color-primary-500;
$color-primary-transparent-disabled-border: $color-basic-transparent-300;

$color-success-focus: $color-success-600;
$color-success-hover: $color-success-400;
$color-success-default: $color-success-500;
$color-success-active: $color-success-600;
$color-success-disabled: $color-basic-transparent-300;
$color-success-focus-border: $color-success-700;
$color-success-hover-border: $color-success-hover;
$color-success-default-border: $color-success-default;
$color-success-active-border: $color-success-active;
$color-success-disabled-border: $color-success-disabled;

$color-success-transparent-focus: $color-success-transparent-300;
$color-success-transparent-focus-border: $color-success-500;
$color-success-transparent-hover: $color-success-transparent-200;
$color-success-transparent-hover-border: $color-success-500;
$color-success-transparent-default: $color-success-transparent-100;
$color-success-transparent-default-border: $color-success-500;
$color-success-transparent-active: $color-success-transparent-300;
$color-success-transparent-active-border: $color-success-500;
$color-success-transparent-disabled: $color-basic-transparent-200;
$color-success-transparent-disabled-border: $color-basic-transparent-300;

$color-info-focus: $color-info-600;
$color-info-hover: $color-info-400;
$color-info-default: $color-info-500;
$color-info-active: $color-info-600;
$color-info-disabled: $color-basic-transparent-300;
$color-info-focus-border: $color-info-700;
$color-info-hover-border: $color-info-hover;
$color-info-default-border: $color-info-default;
$color-info-active-border: $color-info-active;
$color-info-disabled-border: $color-info-disabled;

$color-info-transparent-focus: $color-info-transparent-300;
$color-info-transparent-hover: $color-info-transparent-200;
$color-info-transparent-default: $color-info-transparent-100;
$color-info-transparent-active: $color-info-transparent-300;
$color-info-transparent-disabled: $color-basic-transparent-200;
$color-info-transparent-focus-border: $color-info-500;
$color-info-transparent-hover-border: $color-info-500;
$color-info-transparent-default-border: $color-info-500;
$color-info-transparent-active-border: $color-info-500;
$color-info-transparent-disabled-border: $color-basic-transparent-300;

$color-warning-focus: $color-warning-600;
$color-warning-hover: $color-warning-400;
$color-warning-default: $color-warning-500;
$color-warning-active: $color-warning-600;
$color-warning-disabled: $color-basic-transparent-300;
$color-warning-focus-border: $color-warning-700;
$color-warning-hover-border: $color-warning-hover;
$color-warning-default-border: $color-warning-default;
$color-warning-active-border: $color-warning-active;
$color-warning-disabled-border: $color-warning-disabled;

$color-warning-transparent-focus: $color-warning-transparent-300;
$color-warning-transparent-hover: $color-warning-transparent-200;
$color-warning-transparent-default: $color-warning-transparent-100;
$color-warning-transparent-active: $color-warning-transparent-300;
$color-warning-transparent-disabled: $color-basic-transparent-200;
$color-warning-transparent-focus-border: $color-warning-500;
$color-warning-transparent-hover-border: $color-warning-500;
$color-warning-transparent-default-border: $color-warning-500;
$color-warning-transparent-active-border: $color-warning-500;
$color-warning-transparent-disabled-border: $color-basic-transparent-300;

$color-danger-focus: $color-danger-600;
$color-danger-hover: $color-danger-400;
$color-danger-default: $color-danger-500;
$color-danger-active: $color-danger-600;
$color-danger-disabled: $color-basic-transparent-300;
$color-danger-focus-border: $color-danger-700;
$color-danger-hover-border: $color-danger-hover;
$color-danger-default-border: $color-danger-default;
$color-danger-active-border: $color-danger-active;
$color-danger-disabled-border: $color-danger-disabled;

$color-danger-transparent-focus: $color-danger-transparent-300;
$color-danger-transparent-hover: $color-danger-transparent-200;
$color-danger-transparent-default: $color-danger-transparent-100;
$color-danger-transparent-active: $color-danger-transparent-300;
$color-danger-transparent-disabled: $color-basic-transparent-200;
$color-danger-transparent-focus-border: $color-danger-500;
$color-danger-transparent-hover-border: $color-danger-500;
$color-danger-transparent-default-border: $color-danger-500;
$color-danger-transparent-active-border: $color-danger-500;
$color-danger-transparent-disabled-border: $color-basic-transparent-300;


// Text Colors - general and status
// -----------------------------
$text-basic-color: $color-basic-800;
$text-alternate-color: $color-basic-100;
$text-control-color: $color-basic-100;
$text-disabled-color: $color-basic-transparent-600;
$text-hint-color: $color-basic-600;
 
$text-primary-color: $color-primary-default;
$text-primary-focus-color: $color-primary-focus;
$text-primary-hover-color: $color-primary-hover;
$text-primary-active-color: $color-primary-active;
$text-primary-disabled-color: $color-primary-400;

$text-success-color: $color-success-default;
$text-success-focus-color: $color-success-focus;
$text-success-hover-color: $color-success-hover;
$text-success-active-color: $color-success-active;
$text-success-disabled-color: $color-success-400;

$text-info-color: $color-info-default;
$text-info-focus-color: $color-info-focus;
$text-info-hover-color: $color-info-hover;
$text-info-active-color: $color-info-active;
$text-info-disabled-color: $color-info-400;

$text-warning-color: $color-warning-default;
$text-warning-focus-color: $color-warning-focus;
$text-warning-hover-color: $color-warning-hover;
$text-warning-active-color: $color-warning-active;
$text-warning-disabled-color: $color-warning-400;

$text-danger-color: $color-danger-default;
$text-danger-focus-color: $color-danger-focus;
$text-danger-hover-color: $color-danger-hover;
$text-danger-active-color: $color-danger-active;
$text-danger-disabled-color: $color-danger-400;



// Fonts and Text styles - headings; subtitles; paragraphs; captions; button
// -----------------------------

 //$font-family-primary: unquote("'Roboto', sans-serif");
 $font-family-primary: unquote("-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetical Neue', sans-serif");
 $font-family-secondary: $font-family-primary;

 $text-heading-1-font-family: $font-family-secondary;
 $text-heading-1-font-size: 2.25rem;
 $text-heading-1-font-weight: 700;
 $text-heading-1-line-height: 3rem;

 $text-heading-2-font-family: $font-family-secondary;
 $text-heading-2-font-size: 2rem;
 $text-heading-2-font-weight: 700;
 $text-heading-2-line-height: 2.5rem;

 $text-heading-3-font-family: $font-family-secondary;
 $text-heading-3-font-size: 1.875rem;
 $text-heading-3-font-weight: 700;
 $text-heading-3-line-height: 2.5rem;

 $text-heading-4-font-family: $font-family-secondary;
 $text-heading-4-font-size: 1.625rem;
 $text-heading-4-font-weight: 700;
 $text-heading-4-line-height: 2rem;

 $text-heading-5-font-family: $font-family-secondary;
 $text-heading-5-font-size: 1.375rem;
 $text-heading-5-font-weight: 700;
 $text-heading-5-line-height: 2rem;

 $text-heading-6-font-family: $font-family-secondary;
 $text-heading-6-font-size: 1.125rem;
 $text-heading-6-font-weight: 700;
 $text-heading-6-line-height: 1.5rem;

 $text-subtitle-font-family: $font-family-primary;
 $text-subtitle-font-size: 0.9375rem;
 $text-subtitle-font-weight: 600;
 $text-subtitle-line-height: 1.5rem;

 $text-paragraph-font-family: $font-family-primary;
 $text-paragraph-font-size: 0.9375rem;
 $text-paragraph-font-weight: 400;
 $text-paragraph-line-height: 1.25rem;

 $text-label-font-family: $font-family-primary;
 $text-label-font-size: 0.75rem;
 $text-label-font-weight: 700;
 $text-label-line-height: 1rem;

 $text-caption-font-family: $font-family-primary;
 $text-caption-font-size: 0.75rem;
 $text-caption-font-weight: 400;
 $text-caption-line-height: 1rem;



// Supporting variables - border radius, outline, shadow, divider
// -----------------------------

$border: 1px solid $color-borders;
$border-radius: 3px;
$shadow: 0px 3px 6px #00000029;
$box-shadow: 0px 0px 3px #00000029;

$dimmed-opacity: 0.3;