@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.jumbo-dash {
  @include jumbo-cover('/assets/img/jumbo-dashboard.png');
  
  h1 {
    color: $color-headlines;
  }
}

.setup-steps {
  display: flex;
  justify-content: flex-start;
  color: $color-headlines;
  position: relative;
  margin-bottom: 31px;

  .connecting-line-inv {
      border: 1px dotted $color-borders;
      position: absolute;
      max-width: 480px;
      left: 65px;
      right: 0;
      top: 14px;
      z-index: 1;
  }

  @media screen and (max-width: 768px) {
    .connecting-line-inv-responsive {
      right: 65px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .connecting-line-inv-responsive {
      right: 35px;
    }
    
  }

  .short-line {
    width: 175px;
  }

  .step-item-inv {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 160px;
      z-index: 1;
  
      .step-step-inv {
          font-size: 14px;
          font-weight: bold;
          color: $color-headlines;
          padding-top: 5px;
          background-color: white;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-bottom: 8px;
          border: 1px solid $color-borders;
          text-align: center;

          &:hover {
            cursor: pointer;
          }
      }

      .step-info-inv {
          display: flex;
          align-items: center;
          height: 22px;
          text-align: center;
      }

      @media screen and (max-width: 576px) {
        .step-info-inv {
          font-size: 12px !important;
        }
      }

      .step-info-inv-active {
        font-weight: bold;
      }

      .step-active-inv {
          color: white;
          background-color: $color-developers;
      }

      :last-child {
          font-size: 14px;
      }

  }
}

.setup-step-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-btns-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.card-body-title {
  font-size: 16px;
  font-weight: bold;
  color: $color-headlines;
}

.card-body-subtitle {
  font-size: 14px;
  color: $color-headlines;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sp-cardbody {
  max-width: 850px;
}

.sp-header {
  position: relative;
  width: 100%;
}


.sp-formwrap {
  padding-left: 220px;
  padding-top: 20px;
}

.p-cover-img {
  height: 160px;
  background-size: cover;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .sp-formwrap {
    padding-left: 0;
    padding-top: 100px;
  }
}

@media screen and (max-width: 576px) {
  .residence-responsive {
    flex-direction: column;
  }
}