@import "../../assets/styles/variables.scss";

.card-head-profile-competencies {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 50px;
}

.msection-rows {
  .msection-row {
    display: flex;
    border-bottom: 1px solid $color-borders;
    padding-bottom: 30px;
    margin-bottom: 30px;
    position: relative;

    &:hover .edit-pencil {
      display: block;
    }
    .edit-pencil {
      position: absolute;
      display: none;
    }

    .edit-icon {
      right: 40px;
      top: 10px;
    }

    .delete-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: transparent;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 7px;

      &:hover {
        cursor: pointer;
        background-color: $color-borders;
      }
    }

    .msec-img {
      width: 54px;
      height: 54px;
      margin-left: 5px;
      margin-right: 25px;
    }

    .msec-info {
      color: $color-headlines;

      :nth-child(1) {
        font-size: 16px;
        font-weight: bold;
      }

      :nth-child(2) {
        font-size: 14px;
        font-weight: normal;
      }

      :nth-child(3) {
        font-size: 14px;
        color: $color-placeholder;
      }
    }
  }

  :last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 5px;
  }
}

.c-section-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.weights-btn {
  background-color: transparent;
  padding: 0;
  margin-left: 5px;
  display: flex;
  align-items: center;
  &:hover,
  &:active,
  &:focus {
    color: $color-text;
    background-color: transparent;
    border-color: transparent;
  }
}

/* could add width: 100% but margin-right not working... check later */
@media screen and (max-width: 992px) {
  .rodal-dialog-competencies {
    .rodal-dialog {
      width: 750px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .add-cover-photo-modal {
    .rodal-dialog {
      width: 550px !important;
    }
    .image-uploader {
      canvas {
        width: 500px !important;
      }
    }
  }
  .rodal-dialog-edit-profile-info {
    .rodal-dialog {
      width: 550px !important;
    }
  }
  .rodal-dialog-availability {
    .rodal-dialog {
      width: 550px !important;
    }
  }
  .rodal-dialog-competencies {
    .rodal-dialog {
      width: 550px !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .add-cover-photo-modal {
    .rodal-dialog {
      width: 310px !important;
    }
    .image-uploader {
      canvas {
        width: 240px !important;
      }
    }
  }
  .add-profile-photo-modal {
    .rodal-dialog {
      width: 310px !important;
    }
  }
  .rodal-dialog-edit-profile-info {
    .rodal-dialog {
      width: 310px !important;
      height: 470px !important;
    }
    .edit-profile-break {
      display: block !important;
    }
  }
  .rodal-dialog-education {
    .rodal-dialog {
      width: 310px !important;
    }
    .edit-profile-break {
      display: block;
    }
  }
  .rodal-dialog-experience {
    .rodal-dialog {
      width: 310px !important;
    }
    .edit-profile-break {
      display: block;
    }
  }
  .rodal-dialog-availability {
    .rodal-dialog {
      width: 310px !important;
    }
  }
  .rodal-dialog-competencies {
    .rodal-dialog {
      width: 310px !important;
      height: 550px !important;
    }
  }
}
