.addremove-inputs-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputs {
    display: flex;
    flex-direction: column;
}

.btn-box {

}

.form-field-error {
    color: red;
}