.share-container  {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 0;
    top: 50%;
    margin-right: 3px;
    z-index: 9999;
}

.Demo__some-network__share-button {
    margin-bottom: 3px;
}