@import '../../assets/styles/variables';
@import '../../assets/styles/mixins.scss';

.switch-header {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    margin-bottom: 20px;
    :first-child {
        margin-right: 10px;
        cursor: auto;
    }
    :last-child {
        cursor: pointer;
    }
}

.switch-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: 20px;
}
  
.switch-item {
    flex-grow: 2;
    min-width: 175px;
    // max-width: 250px;
    padding: 12px;
    padding-left: 19px;
    padding-bottom: 10px;
    cursor: pointer;
    border-bottom: 3px solid transparent;

    &:hover {
        transition: background 0.2s ease-in;
    }
}

.item-register {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
        background-color: $color-basic-transparent-200;
        border-bottom: 3px solid $color-auditors;
    }
}

.item-dev {
    &:hover {
        background-color: $color-basic-transparent-200;
        border-bottom: 3px solid $color-developers;
    }
}
.item-dev-active {
    background-color: $color-basic-transparent-200;
    border-bottom: 3px solid $color-developers;
}

.item-inv {
    &:hover {
        background-color: $color-basic-transparent-300;
        border-bottom: 3px solid $color-employers;
    }
}
.item-inv-active {
    background-color: $color-basic-transparent-300;
    border-bottom: 3px solid $color-employers;
}

.item-aud {
    &:hover {
        background-color: $color-basic-transparent-500;
        border-bottom: 3px solid $color-auditors;
    }
}
.item-aud-active {
    background-color: $color-basic-transparent-500;
    border-bottom: 3px solid $color-auditors;
}

.item-edu {
    &:hover {
        background-color: $color-basic-transparent-400;
        border-bottom: 3px solid $color-educationals;
    }
}
.item-edu-active {
    background-color: $color-basic-transparent-400;
    border-bottom: 3px solid $color-educationals;
}

.dev-usercon {
    @include draw-circle(36px, $color-developers, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 37px;
    margin-right: 8px;
}
.inv-usercon {
    @include draw-circle(36px, $color-employers, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 37px;
    margin-right: 8px;
}
.aud-usercon {
    @include draw-circle(36px, $color-auditors, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 37px;
    margin-right: 8px;
}
.edu-usercon {
    @include draw-circle(36px, $color-educationals, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 37px;
    margin-right: 8px;
}

.item-content {
    display: inline-block;
    vertical-align: text-top;
    :first-child {
        display: block;
        line-height: 5px;
        font-weight: bold;
    }
    :last-child {
        font-size: 12px;
        display: inline-block;
        padding-top: 6px;
    }
}

.item-content-register {
    display: block;
    line-height: 5px;
    font-weight: bold;
}