

.why-contain-75 {
    max-width: 100%;
}

.offer-ul {
  list-style: none; /* Remove default bullets */
  font-size: 18px;
}

.offer-ul li {
    padding-bottom: 8px;
}

.offer-ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--color-developers); /* Change the color */
//   font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}



// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .why-contain-75 {
        max-width: 75%;
    }    
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}