@import "../../assets/styles/variables.scss";

.mint-modal-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: normal;
    // padding-left: 90px;
    // padding-right: 90px;
    // padding-top: 32px;
}

.mint-modal-body {
    border-bottom: 1px solid #dddddd;
}

.mint-modal-body-disabled {
    opacity: 0.5;
}

.mint-main-title {
    font-size: 20px;
    font-weight: bold;
    color: $color-headlines;
}

.mint-under-title {
    font-size: 14px;
    color: $color-placeholder;
    text-align: center;
    margin-top: 18px;
    font-weight: normal;
}

.crypto-label {
    white-space: nowrap;
    padding-top: 8px;
    font-size: 14px;
}

.mint-header-column {
    font-size: 14px;
    color: $color-placeholder;
    text-align: center;
    margin-top: 18px;
    font-weight: normal;
}

.mint-available-title {
    text-align: center;
    font-size: 16px;
    color: $color-headlines;
    font-weight: bold;
    margin-top: 0px;
}

.mint-competencies-list {
    display: flex;
    flex-direction: column;
    padding-right: 33px;
    padding-left: 33px;
    padding-bottom: 27px;
    margin-top: 27px;

    input[type="checkbox"]:after {
        border: 3px solid green;
    }
}

.mint-right-col {
    text-align: right;
}

.mint-competencies-row {
    margin-bottom: 42px;
}

.mint-competencies-header-row {
    margin-bottom: 18px;
}

.mint-competency-name {
    font-size: 24px;
    font-weight: bold;
    color: $color-headlines;
}

.mint-competency-cost {
    font-size: 20px;
    font-weight: bold;
    color: $color-headlines;
}

.mint-buttons {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.mint-total-value {
    font-size: 28px;
    font-weight: bold;
    color: $color-headlines;
}

.mint-total-text {
    font-size: 16px;
    font-weight: bold;
    color: $color-headlines;
}

.mint-total-row {
    align-items: center;
}

.mint-total-content {
    display: flex;
    flex-direction: column;
}

.mint-help-bottom {
    font-size: 14px;
    color: $color-placeholder;
    margin-top: 18px;
    font-weight: normal;
}

.mint-low-balance-title {
    font-size: 16px;
    font-weight: bold;
    color: $color-danger;
    margin-top: 70px;
    margin-bottom: 20px;
}

.mint-low-block {
    width: 482px;
    height: 65px;
    font-size: 14px;
    color: $color-headlines;
    text-align: center;
    margin-top: 16px;
}

.mint-low-text {
    font-size: 14px;
    font-weight: bold;
    color: $color-headlines;
}

.mint-buttons-block {
    width: 250px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 43px;
    margin-bottom: 50px;
}

.mint-buy-button {
    height: 33px;
    border: 0;
    border-radius: 3px;
    background-color: $color-headlines;
    color: $color-background !important;
    font-size: 14px;
    &:hover {
        color: $color-background;
    }
}

.mint-deposit-button {
    height: 33px;
    border: 1px solid $color-headlines;
    border-radius: 3px;
    background-color: $color-background;
    color: $color-headlines;
    font-size: 14px;
    &:hover {
        color: $color-headlines;
    }
}

.mint-continue-button {
    width: 332px;
    height: 33px;
    border: 0;
    border-radius: 3px;
    background-color: $color-headlines;
    color: $color-background;
    font-size: 14px;
    &:hover {
        color: $color-background;
    }
}

.mint-final-confirm-button {
    width: 332px;
    height: 40px;
    border: 0;
    border-radius: 3px;
    background-color: $color-headlines;
    color: $color-background;
    font-size: 14px;
    &:hover {
        color: $color-background;
    }
}

.mint-modal-body-confirmation {
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
}

.mint-modal-footer {
    padding: 35px 50px;
    display: flex;
    justify-content: center;
    text-align: center;
}

.mint-modal-confirm-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 210px;
    height: 169px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-top: 16px;
}

.mint-modal-confirm-labels {
    font-size: 14px;
    font-weight: bold;
    color: $color-headlines;
}

.mint-modal-arrow {
    padding-left: 58px;
    padding-right: 58px;
    margin-top: auto;
    margin-bottom: auto;
}

.mint-arrow {
    width: 33px;
}

.mint-estimated-fiat {
    font-size: 16px;
    color: $color-placeholder;
    font-weight: normal;
}

.mint-completed-title {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: $color-headlines;
}

.mint-image {
    margin-top: 50px;
}

@media screen and(max-width: 992px) {
    .rodal-dialog-mint-competencies {
        .rodal-dialog {
            width: 750px !important;
        }
    }
}

@media screen and(max-width: 768px) {
    .rodal-dialog-mint-competencies {
        .rodal-dialog {
            width: 550px !important;
        }
        .mint-modal-arrow {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

@media screen and(max-width: 576px) {
    .rodal-dialog-mint-competencies {
        .rodal-dialog {
            width: 310px !important;
        }
        .mint-modal-title {
            padding-left: 15px;
            padding-right: 15px;
            .mint-main-title {
                text-align: center;
            }
        }
        .crypto-content {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .mint-competencies-list {
            padding-left: 20px;
            padding-right: 20px;
        }
        .mint-competency-name {
            font-size: 18px;
        }
        .mint-competency-cost {
            font-size: 14px;
        }
        .mint-modal-footer {
            display: block;
        }
        .mint-buttons {
            margin-top: 0;
            width: 200px;
            margin-left: auto;
            margin-right: auto;
        }
        .mint-modal-confirm-box {
            width: auto;
        }
        .mint-modal-arrow {
            transform: rotate(90deg);
            margin-top: 16px;
            margin-bottom: 5px;
        }
    }
}