.flag-select-wrap {
  padding: 0;
  padding-top: 3px;
}

.flag-select__btn:focus {
  outline: none;
}

.flag-select__option__icon {
  top: -0.2em !important;
}