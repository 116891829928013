@import "../../assets/styles/variables.scss";

.infobar {
  height: 70px;
  width: 100%;
  // left: 0;
  // top: 0;
  // position: absolute;
  background-color: $color-developers;
  opacity: 0.8;
  color: white;
  display: flex;
  align-items: center;

  .infobar-wrapper {
    display: flex;
    align-items: center;
    width: 100%;

    .infobar-icon {
      width: 76px;
      height: 100%;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .infobar-icon2 {
      width: 65px;
      height: 100%;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    .infobar-content {
      margin-right: 20px;
      margin-left: 20px;
      width: 100%;
      font-size: small;
    }

    .infobar-actions {
      text-align: right;
      padding-left: 0;
      white-space: nowrap;
    }

  }
}



// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .infobar-content {
        font-size: 16px !important;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}