.wrapper {
    // padding-top: 60px;
    // margin-bottom: 55px;

    .fade-enter {
        opacity: 0.01;
    }

    .fade-enter.fade-enter-active {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    .fade-exit {
        opacity: 1;
    }

    .fade-exit.fade-exit-active {
        opacity: 0.01;
        transition: opacity 300ms ease-in;
    }

    div.transition-group {
        position: relative;
    }

    section.route-section {
        // position: absolute;
        // width: 100%;
        // height: 100%;
        // top: 0;
        // left: 0;
        
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .wrapper {
        // margin-bottom: 60px;
    }
}