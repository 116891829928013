@import '../../assets/styles/variables';
@import '../../assets/styles/mixins.scss';

.dropdown-menu {
    min-width: 250px;
    border: none;

    .title {
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
        display: block;
    }

    .subtitle {
        font-size: 14px;
        line-height: 18px;
        color: #737373;
        display: block;
        padding-bottom: 5px;
    }
}

.dropdown-toggle::after {
    display: none !important;
}

.nav-dev {
    @include draw-circle(30px, $color-developers, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 31px;
    margin-right: 0 !important;
}

.nav-inv {
    @include draw-circle(30px, $color-employers, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 31px;
    margin-right: 0 !important;
}

.nav-aud {
    @include draw-circle(30px, $color-auditors, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 31px;
    margin-right: 0 !important;
}

.nav-edu {
    @include draw-circle(30px, $color-educationals, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 31px;
    margin-right: 0 !important;
}

.nav-user-img {
    @include draw-circle(30px, transparent, transparent);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 31px;
    margin-right: 0 !important;
}

.dd-inv-img-active {
    @include draw-circle(77px, $color-employers, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 34px;
    line-height: 75px;
    width: 77px;
}

.dd-dev-img-active {
    @include draw-circle(77px, $color-developers, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 34px;
    line-height: 75px;
    width: 77px;
}

.dd-aud-img-active {
    @include draw-circle(77px, $color-auditors, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 34px;
    line-height: 75px;
    width: 77px;
}

.dd-edu-img-active {
    @include draw-circle(77px, $color-educationals, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: 34px;
    line-height: 75px;
    width: 77px;
}

.dd-role-withimg-active {
    @include draw-circle(77px, transparent, transparent);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 42px;
    width: 77px;
}


.h-balance {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
}

.h-balance:hover {
    cursor: pointer;
    background-color: $color-lighthover;
}

.crypto-logo {
    max-height: 24px;
    height: 24px;
}

.crypto-logo-p {
    padding-right: 0;
}

.center-content {
    text-align: center;
}

.balance {
    padding: 0;
    color: $color-basic-800;
}