@import "../../assets/styles/variables";
@import "../../assets/styles/mixins.scss";

.icon-nitifications {
  font-size: 22px !important;
  display: inline-block;
  vertical-align: text-bottom;
  position: relative;
  left: 0px;
  top: 2px;
}

.icon-email {
  font-size: 22px !important;
  display: inline-block;
  vertical-align: text-bottom;
  position: relative;
  left: 0px;
  top: 2px;
}

.hide-icon {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.n-menu {
  min-width: 375px !important;
  max-width: 375px !important;
  padding: 0;
  border: none;

  .n-items {
    max-height: 500px;
    overflow: auto;
    .n-item {
      border-bottom: 1px solid lightgray;
      :hover {
        background-color: #f5f5f5;
        cursor: pointer;
      }
      .n-row {
        margin: 0;
        padding: 10px 10px 10px 0px;
      }
      .n-title {
        font-weight: bold;
        display: block;
        font-size: 14px;
      }
      .n-text {
        font-size: 14px;
        line-height: 19px;
        display: block;
        overflow: hidden;
        overflow-wrap: break-word;
        text-overflow: ellipsis;
      }
      .n-info {
        font-size: 12px;
        display: block;
      }
    }

    .n-item-red {
      border-left: 4px solid $color-danger-500;
    }

    .n-item-green {
      border-left: 4px solid $color-success-500;
    }
  }
}

.n-menu-header {
  // position: sticky;
  top: 0;
  height: 30px;
  background-color: $color-lighthover;
  width: 100%;
  border: 1px solid $color-borders;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  :nth-child(2) {
    display: flex;
  }

  .n-menu-header-l {
    font-weight: bold;
  }

  .n-menu-header-r {
    :first-child:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    :nth-child(2) {
      margin-left: 5px;
      margin-right: 5px;
    }

    :last-child:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.n-menu-footer {
  position: sticky;
  bottom: 0;
  height: 30px;
  background-color: $color-lighthover;
  width: 100%;
  border: 1px solid $color-borders;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
