@import '../../assets/styles/variables';
@import '../../assets/styles/mixins.scss';

.user-menu-responsive {
    width: 250px;
    border: none;
    padding-top: 15px;

    .title {
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
        display: block;
    }

    .subtitle {
        font-size: 14px;
        line-height: 18px;
        color: #737373;
        display: block;
        padding-bottom: 5px;
    }
}

.dd-inv-img-active-sm {
    @include draw-circle(40px, $color-employers, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 42px;
    width: 40px;
}

.dd-dev-img-active-sm {
    @include draw-circle(40px, $color-developers, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 42px;
    width: 40px;
}

.dd-aud-img-active-sm {
    @include draw-circle(40px, $color-auditors, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 42px;
    width: 40px;
}

.dd-edu-img-active-sm {
    @include draw-circle(40px, $color-educationals, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 42px;
    width: 40px;
}

.dd-role-withimg-active-sm {
    @include draw-circle(40px, transparent, transparent);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 42px;
    width: 40px;
}

.dd-inv-img {
    @include draw-circle(40px, $color-employers, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 41px;    
}

.dd-dev-img {
    @include draw-circle(40px, $color-developers, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 41px;    
}

.dd-aud-img {
    @include draw-circle(40px, $color-auditors, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 41px;    
}

.dd-edu-img {
    @include draw-circle(40px, $color-educationals, $color-background);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 41px;    
}

.dd-role-withimg {
    @include draw-circle(40px, transparent, transparent);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    line-height: 41px;    
}

.dd-active {
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    
    :first-child {
        margin-right: 12px;
    }
}

.dd-roles {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.dd-role {
    display: flex;
    margin-bottom: 12px;

    :first-child {
        margin-right: 12px;
    }

    :last-child {
        padding-top: 3px;
    }
}

.dd-role:hover {
    cursor: pointer;
    background-color: $color-lighthover;
}

.dd-misc {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.dd-misc:hover {
    cursor: pointer;
}

.button-responsive {
    width: 180px;
}

.lang-select {
    margin-left: 5px;
}