@import '../../../assets/styles/variables.scss';

.radio {
    color: $color-headlines;
}

.sp-radio {
    margin-right: 8px;
}

.sp-checkrow {
    margin-left: 40px;
    margin-bottom: 13px;
}

.sp-subtitle {
    font-size: 14px;
    margin-bottom: 14px;
}

.sp-duration-row {
    display: flex;
    align-items: center;
    margin-left: 40px;
}

.sp-input-number {
    width: 80px;
    text-align: center;
}

.sp-renumeration {
    display: flex;
    align-items: center;
}