@import '../variables.scss';


.modal-title {
    font-size: 18px;
    font-weight: bold;
    color: $color-headlines;
    padding-bottom: 8px;
    border-bottom: 1px solid $color-borders;
}
