@import '../variables.scss';

.form-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
    color: $color-headlines;
}

.form-group {
    margin-bottom: 10px;
}

.form-check-label {
    font-size: 14px;
}


.form-control {
    // border: 2px solid $color-basic-400;
}

.form-check {
    // padding-top: 20px;
}

.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    color: initial;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 3px;
}

.custom-control-label {
    padding-top: 2px;
    font-size: 14px;
}

.custom-radio {
    padding-top: 20px;
}

// remove once form validations are done correctly
.custom-control-input:checked~.custom-control-label::before {
    color: $color-basic-100;
    border-color: $color-basic-600;
    background-color: $color-basic-600;
}

.was-validated .custom-control-input:valid~.custom-control-label {
    color: initial;
}

.custom-control-input.is-valid~.custom-control-label::before, .was-validated .custom-control-input:valid~.custom-control-label::before {
    border-color: initial;
}

.custom-control-input.is-valid:checked~.custom-control-label::before, .was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: $color-basic-600;
    background-color: $color-basic-600;
}

/* ReactFlagSelect */
#rfs-btn {
    padding: 2px 5px;
}

.create-competence:hover {
    cursor: pointer;
    text-decoration: underline;
}


.form-sublabel {
    font-size: 14px;
    line-height: 17px;
    color: $color-text;
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-control-invalid {
    border-radius: 4px !important;
    border-color: #dc3545 !important;
}

.tags-valid {
    display: none;
}

.tags-invalid {
    display: inline;
    font-size: 12px;
    color: #dc3545;
}

/* CSS CHECKMARK TICK */
.checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color:white;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:white;
    left:8px;
    top:12px;
}

.stem-inv {
    background-color: $color-headlines;
}

.kick-inv {
    background-color: $color-headlines;
}


/* 3 step header */
.create-title { 
    color: white;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

.create-steps {
    display: flex;
    justify-content: center;
    color: white;
    position: relative;

    .connecting-line {
        // height: 2px;
        // background: #e0e0e0;
        border: 1px dotted white;
        position: absolute;
        width: 300px;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 14px;
        z-index: 1;
    }

    .step-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 140px;
        z-index: 1;
    
        .step-step {
            font-size: 14px;
            font-weight: bold;
            color: $color-headlines;
            padding-top: 5px;
            background-color: white;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-bottom: 8px;
        }

        .step-info {
            display: flex;
            align-items: center;
        }

        .step-active {
            color: white;
            background-color: $color-developers;
        }

        :last-child {
            font-size: 14px;
        }

    }
}