@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixins.scss";

.info-icon-wrap {
  margin-top: -9px;
}

.tooltip-txt {
  font-size: 11px;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

.tooltip-txt-my-work {
  font-size: 11px !important;
  margin: 0;
  padding: 0;
  font-weight: normal !important;
  color: white !important;
}

.jumbo-profile {
  // background-image: url(/assets/img/jumbo-profile.png);
  background-size: cover;
  min-height: 200px;
  border-radius: 0;

  h1 {
    font-size: 41px;
    font-weight: bold;
    color: $color-headlines;
  }

  p {
    color: $color-text;
    font-size: 14px;
  }
}

.card-profile {
  padding: 0;

  .p-cover-img {
    height: 180px;
    background-size: cover;
    width: 100%;
  }

  .p-img-container {
    position: absolute;
    top: 80px;
    left: 20px;
    height: 160px;
    width: 160px;
    z-index: 99;
  }

  .p-circle {
    // position: absolute;
    // top: 80px;
    // left: 20px;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    // background-image: url('/assets/img/default-avatar.png');
    // background-size: cover;
    background-color: white;
    border: 4px solid white;
  }

  .p-img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .p-img-container:hover .p-img-overlay {
    opacity: 1;
  }

  .rodal-p-img {
    width: 600px;
    height: 400px;
  }

  .p-details {
    min-height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
  }

  .p-rating {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 100px;
    padding-left: 30px;
    width: 200px;
    min-width: 200px;
    font-size: 14px;
    color: $color-headlines;
  }

  @media screen and (max-width: 992px) {
    .p-details {
      justify-content: left;
    }

    .p-rating {
      padding-left: 20px;
    }
  }

  .p-location {
    color: $color-text;
  }

  .p-flag {
    // vertical-align: text-top;
    // height: 14px;
    margin-right: 5px;
  }

  .p-info {
    flex-grow: 20;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
  }

  .p-info-name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .p-info-name:first-child {
    font-size: 24px;
    font-weight: bold;
  }

  .p-star {
    // font-size: 18px;
    // vertical-align: top;
    color: #ffd700;
  }

  .p-info-tagline {
    font-size: 16px;
    color: $color-text;
  }

  .p-info-status {
    font-size: 14px;
    color: #62bd2a;
  }

  .p-description {
    margin-top: 15px;
    font-size: 14px;
    color: $color-headlines;
    display: flex;
    align-items: center;
  }

  .section-img {
    margin-left: 40px;
    margin-right: 20px;
  }
}

.p-section {
  font-size: 14px;
  color: $color-headlines;
  display: flex;
  align-items: center;
}

.section-img {
  margin-left: 40px;
  margin-right: 20px;
}

.add-link {
  color: $color-developers;
  font-size: 14px;
  margin-top: 4px;

  &:hover {
    cursor: pointer;
  }
}

.card-availability {
  color: $color-headlines;
  font-size: 16px;
  padding: 0;

  .p-rates {
    padding-top: 22px;
    padding-left: 16px;
    padding-bottom: 16px;
    font-size: 16px;

    .caption {
      font-weight: bold;
    }

    .val {
      margin-bottom: 18px;

      .val-muted {
        font-size: 14px;
        color: $color-placeholder;
      }
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  .p-stats {
    border-top: 1px solid $color-borders;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    .p-stats-item {
      font-size: 24px;
      width: 33%;
      font-weight: bold;
      text-align: center;
      flex-grow: 1;
      line-height: 10px;
      padding-top: 16px;
      padding-bottom: 16px;

      :first-child {
        display: block;
        line-height: 30px;
      }

      :last-child {
        font-size: 12px;
        font-weight: normal;
        white-space: pre-wrap;
      }
    }
  }
}

.inbetween {
  font-size: 14px;
  color: $color-text;
}

.drop-zone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

/* Image Scale Slider */
.img-scale-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.img-scale-slider:hover {
  opacity: 1;
}

.img-scale-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: $color-projects;
  cursor: pointer;
}

.img-scale-slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: $color-projects;
  cursor: pointer;
}

.mp-img-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.drop-zone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.profile-setup-progress {
  margin-bottom: 25px;

  .progress-title {
    font-size: 16px;
    color: $color-headlines;
    display: flex;
    align-items: center;

    div {
      margin-right: 5px;
    }
  }

  .progress-wrapper {
    height: 4px;
    width: 100%;
    margin-top: 8px;
    background-color: $color-borders;

    .setup-progress {
      height: 100%;
      width: 50%;
      background-color: $color-developers;
      border-radius: 2px;
    }
  }
}

.tooltip-parent {
  margin: 0;

  .tooltip-title {
    display: flex;
    align-items: center;

    div {
      margin-right: 5px;
    }
  }
}

.empty-section {
  border: 2px dashed $color-borders;
  height: 100px;
  margin-bottom: 1rem;
}

.c-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-borders;

  .c-name {
    font-size: 16px;
    font-weight: bold;
  }

  .c-publisher {
  }
}

.section-rows {
  .section-row {
    display: flex;
    border-bottom: 1px solid $color-borders;
    padding-bottom: 30px;
    margin-bottom: 30px;
    align-items: center;

    .sec-img {
      width: 54px;
      height: 54px;
      margin-left: 5px;
      margin-right: 25px;
    }

    .sec-info {
      color: $color-headlines;
      width: 100%;

      :nth-child(1) {
        font-size: 16px;
        font-weight: bold;
      }

      :nth-child(2) {
        font-size: 14px;
        font-weight: normal;
      }

      :nth-child(3) {
        font-size: 14px;
        color: $color-placeholder;
      }
    }
  }

  :last-child {
    border-bottom: none;
    margin-bottom: 0;
    // padding-bottom: 5px;
  }
}

.edu-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-project-item {
  border: none;
  &:hover {
    cursor: unset;
    background-color: white;
    border-left: 2px solid transparent;
  }
}

.mint-competencies-button {
  border: 0;
  border-radius: 3px;
  width: 187px;
  height: 27px;
  background-color: $color-headlines;
  color: $color-background;
  font-size: 14px;
  margin-left: 400px;
}

.nav-tab-competences {
  flex: 1;
  max-width: fit-content;
  color: $color-headlines !important;
  text-align: center;
  font-size: 14px;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    transition: background 0.2s ease-in;
  }

  &.active {
    font-weight: 500;
  }

  :first-child {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: text-top;
  }
}

.competences-nav-public {
  justify-content: left !important;
  padding-bottom: 0 !important;
  margin: 0 1rem;
}

.button-hire-freelancer {
  align-items: flex-end;
  border: 0;
  width: 100px;
  height: 27px;
  font-size: 14px;
  font-weight: bold;
  background-color: $color-background;
  color: $color-headlines;
  margin-left: 250px;
}

.button-compare-freelancer {
  align-items: flex-end;
  border: 0;
  border-radius: 3px;
  width: 200px;
  height: 27px;
  background-color: $color-headlines;
  color: $color-background;
  font-size: 14px;
  font-weight: bold;
}

.competencies-public-tabs {
  padding-bottom: 0;
}

.competences-nav-public {
  border-bottom: 1px solid $color-borders;
}

.red-circle {
  margin-right: 8px;
}

.c-group-title {
  font-size: 20px;
  font-weight: bold;
  color: $color-headlines;
}

.c-accordion {
  padding-left: 0;
  padding-right: 0;
  padding-top: 30px;
  padding-bottom: 20px;
  background-color: $color-background;
}

.c-card-body {
  padding-left: 0;
  padding-right: 0;
}

.c-tag-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin-bottom: 18px;
}

.c-group-tags {
  display: flex;
  flex-direction: column;
}

.c-weights-count {
  color: #444444;
  border-bottom: 1px #131f3d dashed;
  font-size: 14px;
  &:hover {
    color: $color-developers;
    border-color: $color-developers;
    cursor: pointer;
  }
}

.c-tags {
  width: fit-content;
  background-color: rgba($color: #008dce, $alpha: 0.1);
  color: #008dce;
  font-size: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 3px;
  padding-top: 4px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    font-weight: 500;
  }
}

.c-dropdown-content {
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 472px;
  padding-left: 16px;
  padding-right: 20px;
  padding-top: 16px;
}

.c-dropdown-title {
  font-size: 16px;
  font-weight: 600;
  color: $color-headlines;
}

.c-info-dropdown {
  float: right;
  height: 20px;
}

.c-gray-text-sm {
  font-size: 14px;
  color: $color-borders;
  margin-top: 12px;
}

.c-minted-icon {
  margin-right: 1rem;
  height: 18px;
  margin-top: auto;
  margin-bottom: auto;
}

.c-dropdown-content-mint {
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 100px;
  padding-left: 16px;
  padding-right: 20px;
  padding-top: 16px;
}

.c-mint-text {
  font-size: 14px;
  color: $color-headlines;
}

.c-mint-info {
  margin-top: 12px;
}

.c-more-info {
  font-size: 16px;
  font-weight: bold;
  color: $color-headlines;
  margin-right: 16px;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.c-mint-dropdown-toggle {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  align-items: center;
}

.c-dropdown-left-row {
  display: flex;
  flex-direction: column;
}

.c-dropdown-right-col {
  text-align: right;
  padding-left: 0;
}

.c-dropdown-icon {
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 5px;
}

.c-dropdown-label {
  font-size: 13px;
  font-weight: bold;
  color: $color-headlines;
}

.c-dropdown-left-content {
  width: 265px;
}

.c-rating-label {
  font-size: 14px;
  font-weight: bold;
  color: $color-headlines;
}

.c-minted-icon-dropdown {
  height: 20px;
  margin-bottom: auto;
  margin-top: auto;
  margin-right: 8px;
}

.c-dropdown-project-name {
  font-size: 16px;
  color: $color-developers;
}

.c-main-dropdown-content {
  margin-top: 16px;
  margin-bottom: 10px;
}

@media screen and (max-width: 992px) {
  .card-profile .p-img-container {
    position: absolute;
    top: 80px;
    left: 50%;
    margin-left: -80px;
    height: 160px;
    width: 160px;
  }

  .p-details {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .btn-cover {
    font-size: 12px;
  }
}

.p-info-name {
  display: flex;
  align-items: center;
  // justify-content: center;
  // line-height: 23px;

  :nth-child(2) {
    display: flex;
    align-items: baseline;
  }

  .p-star {
    // font-size: 22px !important;
    // vertical-align: top;
    color: #ffd700;
  }

  .rating-big {
    font-size: 29px !important;
    font-weight: bold;
  }

  .rating-small {
    font-size: 16px !important;
  }
}

/* 
  Experiment with blured card around avatar image
  Overriding some existing classes...should overwrite
 */
.transbox {
  position: relative;
  width: 190px;
  height: 190px;
  background: #fff;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.sp-avatar-wrapper {
  width: 160px;
  height: 160px;
  margin-top: -95px;
  margin-left: 25px;
  position: absolute;
}

.sp-avatar {
  border: none;
}

.sp-avatar-overlay {
  position: absolute;
  margin-top: -95px;
  display: flex;
  flex-direction: column;
  background-color: #232323;
  opacity: 0.4;
  width: 100%;
  align-items: center;
  font-size: 14px;
  color: white;
  height: 80px;
  padding-top: 20px;
  border-bottom-left-radius: 320px;
  border-bottom-right-radius: 320px;
  z-index: 10;
  margin-left: 15px;
}

.card-profile .p-rating {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 110px;
  padding-left: 25px;
  width: 200px;
  min-width: 200px;
  font-size: 14px;
  color: #131f3d;
}

.card-profile .p-info {
  flex-grow: 20;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  padding-left: 40px;
}

.card-profile .p-description {
  margin-top: 20px;
  font-size: 14px;
  color: #131f3d;
  display: flex;
  align-items: center;
}
/* 
  End Experiment with blured card around avatar image...
 */

/* LIST ITEMS */
.card-item {
  padding: 0;
  margin-bottom: 0;
  border-radius: 0;
  border-bottom: none;

  &:hover {
    background-color: $color-lighthover;
    border-left: 3px solid $color-projects;

    .card-body {
      padding-left: 21px;
    }
  }

  .card-body {
    padding-top: 21px;
    padding-left: 23px;
    display: flex;
  }

  .card-details {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .card-sep {
    border-left: 1px solid $color-borders;
    margin-left: 24px;
    margin-right: 24px;
  }

  .card-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 175px;
    text-align: center;

    :first-child {
      font-size: 29px;
      font-weight: bold;
    }
  }

  .card-title {
    font-size: 16px;
    font-weight: bold;
    color: $color-headlines;
  }

  .card-desc {
    font-size: 14px;
    white-space: "pre-wrap";
  }

  .browse-namelink {
    cursor: pointer;
    display: flex;
  }

  .card-info {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: $color-headlines;

    span {
      padding-right: 8px;
    }
  }
}

.card-thumb {
  margin-right: 20px;
}

@media screen and (max-width: 768px) {
  .card-thumb {
    margin-bottom: 10px;
  }
}

.p-thumb {
  width: 54px;
  border-radius: 2px;
}

.user-thumb {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.card-tags {
  font-size: 14px;
  line-height: 17px;
  color: $color-projects;

  span {
    color: $color-projects;
    background-color: $color-basic-transparent-200;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 3px;
    padding-left: 7px;
    padding-right: 7px;
    margin-right: 8px;
    margin-bottom: 8px;
    display: inline-block;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.browse-namelink-freelancer {
  cursor: pointer;
  display: flex;
}

@media (max-width: 767px) {
  .card-item .card-body {
    flex-direction: column;
  }
}
/* END LIST ITEMS */
