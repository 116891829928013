@font-face {
  font-family: 'ecta';
  src:  url('/assets/fonts/ecta.eot?w6givq');
  src:  url('/assets/fonts/ecta.eot?w6givq#iefix') format('embedded-opentype'),
    url('/assets/fonts/ecta.ttf?w6givq') format('truetype'),
    url('/assets/fonts/ecta.woff?w6givq') format('woff'),
    url('/assets/fonts/ecta.svg?w6givq#ecta') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ecta' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down-filled:before {
  content: "\e934";
}
.icon-arrow-up-filled:before {
  content: "\e935";
}
.icon-arrow-down:before {
  content: "\e932";
}
.icon-arrow-up:before {
  content: "\e933";
}
.icon-audit:before {
  content: "\e90b";
}
.icon-edit:before {
  content: "\e91d";
}
.icon-xls:before {
  content: "\e900";
}
.icon-stopwatch:before {
  content: "\e901";
}
.icon-star:before {
  content: "\e902";
}
.icon-social-care:before {
  content: "\e903";
}
.icon-questions:before {
  content: "\e904";
}
.icon-ppt:before {
  content: "\e905";
}
.icon-png:before {
  content: "\e906";
}
.icon-pdf:before {
  content: "\e907";
}
.icon-open_tasks:before {
  content: "\e908";
}
.icon-notifications:before {
  content: "\e909";
}
.icon-network:before {
  content: "\e90a";
}
.icon-mortarboard:before {
  content: "\e90c";
}
.icon-manage_account:before {
  content: "\e90d";
}
.icon-jpg:before {
  content: "\e90e";
}
.icon-information:before {
  content: "\e90f";
}
.icon-generic_attachment:before {
  content: "\e910";
}
.icon-facebook-logo:before {
  content: "\e911";
}
.icon-docx:before {
  content: "\e912";
}
.icon-contact_us:before {
  content: "\e913";
}
.icon-calendar_timeframe:before {
  content: "\e914";
}
.icon-budget:before {
  content: "\e915";
}
.icon-brain:before {
  content: "\e916";
}
.icon-blockchain:before {
  content: "\e917";
}
.icon-bell:before {
  content: "\e918";
}
.icon-back_arrow:before {
  content: "\e919";
}
.icon-competence:before {
  content: "\e91a";
}
.icon-dashboard:before {
  content: "\e91b";
}
.icon-developers:before {
  content: "\e91c";
}
.icon-educational_institutions:before {
  content: "\e91e";
}
.icon-email:before {
  content: "\e91f";
}
.icon-investor:before {
  content: "\e920";
}
.icon-my_clients:before {
  content: "\e921";
}
.icon-nitifications:before {
  content: "\e922";
}
.icon-projects:before {
  content: "\e923";
}
.icon-search:before {
  content: "\e924";
}
.icon-settings:before {
  content: "\e925";
}
.icon-attachment:before {
  content: "\e926";
}
.icon-camera:before {
  content: "\e927";
}
.icon-competences:before {
  content: "\e928";
}
.icon-delete:before {
  content: "\e929";
}
.icon-education:before {
  content: "\e92a";
}
.icon-experience:before {
  content: "\e92b";
}
.icon-good-job:before {
  content: "\e92c";
}
.icon-high_five:before {
  content: "\e92d";
}
.icon-history:before {
  content: "\e92e";
}
.icon-image:before {
  content: "\e92f";
}
.icon-profile-info:before {
  content: "\e930";
}
.icon-send:before {
  content: "\e931";
}
