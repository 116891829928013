$border-radius: 20px;

$primary: #709fdc;
$base: #071739;
$shadow-color:  #274684;
$lighter-shadow: rgba($shadow-color, .2);
$white: #fff;
$gray: #8c8c8c;
$lighter-gray: rgba($gray, .1);
$time-line-width: 300px;
$transition: .3s all ease;

@mixin dragIndicator($property, $background, $z-index) {
  #{$property}{
      position: absolute;
      font-size: 12px;
      top: 0;
      z-index: $z-index;
      width: 0;
      height: 5px;
      border-radius: 5px;
      background: $background;
      &:hover{
        &::before{
          opacity: 1;
        }
        &::after{
          opacity: 1;
        }
      }
      &::before{
        opacity: 0;
        content: attr(data-content);
        display: block;
        position: absolute;
        top: -40px;
        right: -23px;
        width: 40px;
        padding: 3px;
        text-align: center;
        color: white;
        background: $shadow-color;
        border-radius: $border-radius;
    }
    &::after{
      opacity: 0;
      content:'';
      display: block;
      position: absolute;
      top: -18px;
      right: -8px;
      border-top: 8px solid $shadow-color;
      border-left:8px solid transparent;
      border-right:8px solid transparent;
    }
    #{$property}-drag{
      position: absolute;
      right: -7.5px;
      top: -5px;

      width: 15px;
      height: 15px;

      border-radius: 50%;
      background: $base;
      transition: all .3s;
      &:hover{
        box-shadow: 0 0 0 6px $lighter-shadow;
      }
    }
  }
}
.card-range{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 10px;
    padding-top: 10px;
    margin-top: 10px;
    background: $white;
    overflow: hidden;
    .current-value{
      width: 300px;
      justify-content: space-between;
      label{
        display: inline-flex;
        width: 50px;
        font-size: 20px;
      }
    }
    .bracket{
      width: 100px;
    }
    .values{
      display: flex;
      justify-content: space-between;
      font-weight: 600;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 10px;
      width: $time-line-width;
    }
    #slider{
      position: relative;
      width: $time-line-width;
      height: 5px;
      background: $primary;
      border-radius: 5px;
      cursor: pointer;
      
      @include dragIndicator("#min", $primary, 2);
      @include dragIndicator("#max", $shadow-color, 1);
    }}

.fa-instagram{
  
  color: $base;
  top: 3%;
  right: 2%;
  font-size: 15px;
}
.fa-instagram:hover{
  font-size: 5px;
  color: $shadow-color;
  transition: all .1s linear;
  cursor: pointer;
}


input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

*:focus{
  outline: none;
  box-shadow: 0 0 0 2px $primary;
}
