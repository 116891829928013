@import '../../../assets/styles/variables';

/* DEFAULT COLOR VARIABLES */

$ball-color: #ffffff;
$active-color: $color-developers;
$inactive-color: #cccccc;

/* DEFAULT SIZING VARIABLES */

$switch-size: 32px;
$ball-spacing: 2px;
$stretch-factor: 1.625;

/* DEFAULT CLASS VARIABLE */

$switch-class: 'switch-toggle';

/* STYLES */

@mixin switch($size: $switch-size, $spacing: $ball-spacing, $stretch: $stretch-factor, $color: $active-color, $class: $switch-class) {
    // SELECTOR VARIABLES
    $self: '.' + $class;
    $on: #{$self}--on;
    $off: #{$self}--off;
    // SWITCH VARIABLES
    $active-color: $color;
    $switch-size: $size;
    $ball-spacing: $spacing;
    $stretch-factor: $stretch;
    $ball-size: $switch-size - ($ball-spacing * 2);
    $ball-slide-size: ($switch-size * ($stretch-factor - 1) + $ball-spacing);
    // SWITCH STYLES
    height: $switch-size;
    width: $switch-size * $stretch-factor;
    cursor: pointer !important;
    user-select: none !important;
    position: relative !important;
    display: inline-block;
    &#{$on},
    &#{$off} {
        &::before,
        &::after {
            content: '';
            left: 0;
            position: absolute !important;
        }
        &::before {
            height: inherit;
            width: inherit;
            border-radius: $switch-size / 2;
            will-change: background;
            transition: background .4s .3s ease-out;
        }
        &::after {
            top: $ball-spacing;
            height: $ball-size;
            width: $ball-size;
            border-radius: $ball-size / 2;
            background: $ball-color !important;
            will-change: transform;
            transition: transform .4s ease-out;
        }
    }
    &#{$on} {
        &::before {
            background: $active-color !important;
        }
        &::after {
            transform: translateX($ball-slide-size);
        }
    }
    &#{$off} {
        &::before {
            background: $inactive-color !important;
        }
        &::after {
            transform: translateX($ball-spacing);
        }
    }
}

@function get-switch-class($selector) {
    // First parse the selector using `selector-parse`
    // Extract the first selector in the first list using `nth` twice
    // Extract the first simple selector using `simple-selectors` and `nth`
    // Extract the class name using `str-slice`
    @return str-slice(nth(simple-selectors(nth(nth(selector-parse($selector), 1), 1)), 1), 2);
}

.switch {
    $self: &;
    $toggle: #{$self}-toggle;
    $class: get-switch-class($toggle);
    // default theme
    &#{$self}--default>#{$toggle} {
        // Always pass the $class to the mixin
        @include switch($class: $class);
    }
    // graphite-small theme
    &#{$self}--graphite-small>#{$toggle} {
        // A smaller switch with a `gray` active color
        // Always pass the $class to the mixin
        @include switch($color: gray, $size: 20px, $class: $class);
    }
}