@import "../../assets/styles/variables";
@import "../../assets/styles/mixins.scss";
@import "../../assets/styles/partials/buttons";

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky-top {
  z-index: 100 !important;
}

.navbar {
  background-color: $color-background;
  box-shadow: $box-shadow;
  padding: 0;

  .navbar-nav .nav-link {
    color: $color-headlines;
    padding: 0px;
    padding-right: 17px;
    padding-left: 17px;
    border-bottom: 3px solid transparent;
    border-top: 3px solid transparent;
    line-height: 54px;

    &:hover,
    &:focus,
    &:active {
      color: $color-headlines;
      background-color: $color-lighthover;
      border-bottom: 3px solid $color-developers;
      transition: background 0.2s ease-in;
    }

    :first-child {
      font-size: 18px;
      margin-right: 6px;
    }

    :first-child::before {
      vertical-align: -2px;
    }

    .badge {
      display: inline-block;
      padding: 0.01em 0.2em;
      font-size: 11px;
    }

    .nav-badge {
      z-index: 15;
      position: relative;
      left: -11px;
      top: -9px;
      color: $color-developers;
      background-color: $color-developers;
      border: 1px solid $color-developers;
    }
    .nav-badge2 {
      z-index: 15;
      position: relative;
      left: -15px;
      top: -9px;
      // color: $color-developers;
      color: white;
      background-color: $color-developers;
      border: 1px solid $color-developers;
    }
  }

  .navbar-brand {
    font-size: inherit;
    line-height: inherit;
    margin-right: 30px;
    padding: 0;
  }

  .navbar-collapse {
    background-color: transparent;
  }

  .navbar-brand:hover {
    cursor: pointer;
  }

  .header-lang {
    font-size: 16px;
    background-color: transparent;
    border: none;
    color: $color-headlines;
    padding: 0;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .separator-wrapper {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .header-separator {
    border-right: 1px solid $color-basic-1100;
    height: 100%;
    margin-left: 4px;
    margin-right: 4px;
  }

  .btn-wrapper {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .btn-header {
    color: $color-background;
    background-color: $color-developers;
    height: 100%;
    padding: 0;
    padding-top: 7px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .btn-header:hover,
  .btn-header:focus,
  .btn-header:active,
  .btn-header.active,
  .open > .dropdown-toggle.btn-header {
    color: $color-lighthover;
  }

  .btn-header:active,
  .btn-header.active {
    color: $color-lighthover;
  }

  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: none;
    box-shadow: none;
  }
}

/* Landing styles */
.navbar-landing {
  background-color: transparent;
  box-shadow: none;
}
.nav-link-landing {
  color: $color-background !important;
  &:hover,
  &:focus,
  &:active {
    color: $color-background !important;
    background-color: transparent !important;
    border-bottom: 3px solid $color-developers;
    transition: background 0.2s ease-in;
  }
}

.header-lang-landing {
  font-size: 16px;
  background-color: transparent !important;
  border: none;
  color: $color-background !important;
  padding: 0;
  &:focus {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}



/* OffCanvas menu */
#wns-nav {
  display: none;
}
#wns-nav-mobile {
  display: flex;
  height: 60px;
}
.mobile-navbar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  
}
.offcanvas-close {
  position: absolute;
  right: 8px;
  top: 8px;
  &:hover{
      cursor: pointer;
  }
}
.offcanvas-username {
  font-size: 14px;
  font-weight: bold;
}
.offcanvas-role {
  font-size: 14px;
}
.xs-join {
  font-weight: bold;
  color: var(--color-headlines);
}
.nav-xs-divider {
  border-top: 1px solid var(--color-borders);
}
.oc-link {
  line-height: 34px !important;
}

.btn-become {
  border: none !important;
}

@media (max-width: 991px) {
  .offcanvas-collapse {
    position: fixed;
    top: 0; /* Height of navbar */
    bottom: 0;
    width: 300px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 10px;
    overflow-y: auto;
    background-color: var(--bg) !important;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    -webkit-transform: translateX(-110%);
    transform: translateX(-110%);
    //   border: 1px solid lightgray;
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.6); 
    box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.6);
  }
  .offcanvas-collapse.open {
    -webkit-transform: translateX(-1rem);
    transform: translateX(-1rem); /* Account for horizontal padding on navbar */
  }
}

@media screen and (max-width: 992px) {
  .navbar {
    padding: 8px 14px;
  }
  .navbar-brand {
    margin-left: 7px;
    margin-right: 0;
  }
}

@media screen and (min-width: 992px) {
  #wns-nav {
    display: flex;
  }
  #wns-nav-mobile {
    display: none;
  }
}
