.accordion-user-skills {
  .accordion-user-skills_item {
    .accordion-user-skills_header {
      border: none;
      button {
        padding: 1rem;
      }
      button::after {
        position: absolute;
        width: 1rem;
        height: 1rem;
        background-size: 1rem;
      }
      .skill-issued-by {
        font-size: 16px;
        font-weight: 500;
        margin-left: 25px;
      }
    }
  }
}

.user-defined-skills {
  display: flex;
  flex-wrap: wrap;
  .skill-tag {
    margin-bottom: 1rem;
    display: flex;
    span {
      font-size: 14px;
      white-space: nowrap;
      color: var(--color-projects);
      background-color: rgba(0, 141, 206, 0.1);
      padding: 3px 1rem;
      border-radius: 3px;
      margin-right: 1rem;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.earned-skills {
  .skill-tag {
    display: flex;
    .earned-skill {
      font-size: 14px;
      white-space: nowrap;
      color: var(--color-projects);
      background-color: rgba(0, 141, 206, 0.1);
      padding: 3px 1rem;
      border-radius: 3px;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
