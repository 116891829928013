.fake-dropdown {
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.arrow-fake-dropdown {
  position: absolute;
  right: 6px;
  top: 13px;
  border: solid #495057 !important;
  border-width: 0 2px 2px 0 !important;
  padding-top: 2px !important;
  padding-bottom: 3px !important;
  padding-right: 2px !important;
  padding-left: 3px !important;

  &.up {
    top: 17px;
  }
}

.adv-filters-container {
  margin-top: 16px;
  margin-bottom: 10px;
  border: 1px solid $color-projects;
  border-radius: 8px;
  padding: 22px 22px;
  background-color: var(--white);

  .advf-body {
    margin-top: 0.25rem;
  }

  .advf-header {
    color: $color-projects;
    font-size: 18px;
    // font-weight: bold;
    // border-bottom: 3px solid;
    width: 100%;
    display: flex;
    justify-content: space-between;

    .title {
      border-bottom: 3px solid;
    }

    .filters-clear {
      font-size: 14px;
      font-weight: normal;
      &:hover {
        cursor: pointer;
        border-bottom: 1px solid;
      }
    }
  }

  .advf-title {
    font-size: 16px;
    color: $color-headlines;
    // font-weight: bold;
    width: 100%;
    border-bottom: 1px solid $color-projects;
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .filter-date {
    padding-right: 5px;
    padding-left: 5px;
    // margin-bottom: 13px;

    &::-webkit-datetime-edit {
      padding: 0;
      text-align: center;
    }
    // &::-webkit-datetime-edit-fields-wrapper { background: silver; }
    // &::-webkit-datetime-edit-text { color: red; padding: 0; }
    // &::-webkit-datetime-edit-month-field { color: blue; }
    // &::-webkit-datetime-edit-day-field { color: green; }
    // &::-webkit-datetime-edit-year-field { color: purple; }
    // &::-webkit-inner-spin-button { display: none; }
    &::-webkit-calendar-picker-indicator {
      padding: 0;
      margin: 0;
    }
  }
}
@media (min-width: 768px) {
  .adv-filters-container {
    padding: 22px 32px;
  }
}

.audit-data-filters {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid lightgray;

  .audit-data-filter {
    padding-top: 12px;
    width: 100%;
    text-align: center;
    padding-bottom: 12px;
  }

  .f1 {
    &:hover,
    &.active {
      background-color: rgba($color-status-negotiating, 0.3);
    }
  }
  .f2 {
    &:hover,
    &.active {
      background-color: rgba($color-status-published, 0.3);
    }
  }
  .f3 {
    &:hover,
    &.active {
      background-color: rgba(31, 58, 134, 0.3);
    }
  }
  .f4 {
    &:hover,
    &.active {
      background-color: rgba($color-status-in-progress, 0.6);
    }
  }

  .active {
    // text-decoration: underline;
  }

  .audit-data-filter:hover {
    cursor: pointer;
    // text-decoration: underline;
  }
}
