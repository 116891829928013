@import './variables.scss';


.btn-outline-secondary {
    color: $color-basic-100;
    border: 1px solid $color-placeholder !important;
}

.dropdown-menu {
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #fff;
    border-radius: 0;
    box-shadow: 0px 3px 6px #00000029;
}

.dropdown-menu[data-bs-popper] {
    margin-top: 0;
}

.dropdown-divider {
    height: 0 !important;
}

.text-muted {
    color: #737373!important;
}