@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.jumbo-dash {
  @include jumbo-cover('/assets/img/jumbo-dashboard.png');
}

.side-sett-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    color: $color-headlines;

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      cursor: pointer;
    }

    .side-sett-itemicon {
      margin-right: 10px;
      font-size: 22px !important;
    }

    .sidelink {
      padding-top: 6px;

      &:hover {
        cursor: pointer;
        font-weight: bold;
      }
    }

    .sk-active {
      font-weight: bold;
    }
}

.sett-col-title {
  font-size: 14px;
  font-weight: bold;
}

.sett-col-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}