/*
    NEW GLOBAL STYLES FILE...2021
*/

/* Variables */
:root {

  // Main/Basic Colors
  --color-projects:     #1f3a86;
  --color-developers:   #008dce;
  --color-employers:    #ffc500;
  --color-educationals: #fb7400;
  --color-auditors:     #0f5ce4;
  --color-competencies: #359b4f;
  --color-headlines:    #003166;
  --color-text:         #444444;
  --color-placeholder:  #afafaf;
  --color-borders:      #dddddd;
  --color-lighthover:   #fafafa;
  --color-background:   #ffffff;

  --color-danger:       #FF0000;
  --color-success:      #23D59B;

  --bg: #ffffff;

  --font-primary: "Roboto", sans-serif;
}

.dark-mode {
  --bg: black;
  --color-text: white;
}

body {
  background: var(--bg);
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.jumbo-wns {
  display: flex;
  align-items: center;
  // margin-top: -3px;
  display: none !important;
}

@media (min-width: 576px) {
  .container {
      max-width: 100%;
  }
}

@media (min-width: 768px) {
  .jumbo-wns {
    display: flex !important;
  }
}

@media (min-width: 992px) {
  .container {
      max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .container {
      max-width: 1333px;
  }
}

// Check for light/dark system os preference
// @media (prefers-color-scheme: light) {
//   body {
//     background: var(--bg);
//     color: var(--text-color);
//   }
// }
//
// @media (prefers-color-scheme: dark) {
//   body {
//     background: var(--bg);
//     color: var(--text-color);
//   }
// }
