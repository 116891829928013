
@mixin draw-circle($radius, $bgColor, $color) {
    background-color: $bgColor;
    color: $color;
    height: $radius;
    width: $radius;
    border-radius: 50%;
    display: inline-block;
}

@mixin jumbo-cover($img)  {
    background-image: url($img);
    background-size: cover;
    height: 200px;
    border-radius: 0;
    background-position-x: right;
    // padding: 2rem 1rem;
    display: flex;
    align-items: center;

    h1 {
        font-size: 2.5rem;
        font-weight: bold;
        color: var(--color-headlines);
        z-index: 100;
        margin: 0;
    }
  
    p {
      color: $color-text;
      font-size: 14px;
    }
}

@media screen and(min-width: 576px) and(max-width: 992px) {
  .jumbo-h1 {
      font-size: 2rem !important;
      max-width: 35%;
    }
}

@media screen and(min-width: 992px) and(max-width: 1200px) {
  .jumbo-h1 {
      max-width: 50%;
    }
}

@mixin dimmed($color1, $color2, $opacity) {
    position: relative;
    &:after {
      content: " ";
      z-index: 10;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: transparent linear-gradient(180deg, $color1 0%, $color2 100%) 0% 0% no-repeat padding-box;
      opacity: $opacity;
    }
  }