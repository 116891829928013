@import '../../assets/styles/variables.scss';


.hide-section {
    display: none;
}

.delete-role-info {
    display: flex;
    margin-bottom: 24px;
}

.delete-role-details {
    font-weight: bold;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.google-connect {
    .btn-block {
        width: 250px;
        box-shadow: none !important;
        color: $color-headlines !important;
        opacity: 1 !important;
        border: 1px solid $color-borders !important;
        border-radius: .25rem !important;
    }
}