@import "../variables.scss";


/* DateTimePicker (AirBnB) */
.DateRangePickerInput__withBorder {
    border-radius: .25rem !important;
    border: 1px solid #ced4da !important;
    width: 100% !important;
}

.DateInput {
    background: transparent !important;
    // width: 47% !important;
    padding-left: 5% !important;
}

.DateInput_input {
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    padding: 6px 11px 4px !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: $color-projects !important;
    border: 1px double $color-projects !important;
    color: #fff;
}

.DateInput_input__focused {
    border-bottom: 2px solid $color-projects !important;
}

.CalendarDay__hovered_span {
    background: $color-basic-transparent-100 !important;
    border: 1px double $color-basic-transparent-100 !important;
}

.CalendarDay__selected_span {
    background: $color-basic-transparent-100 !important;
    border: 1px double $color-basic-transparent-100 !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid $color-projects !important;
}
