.wns-footer {
    background-color: var(--color-headlines);
    margin-top: auto;
    // z-index: -1;

    .footer-socials {
        &:hover {
            cursor: pointer;
        }
    }

    .footer-lang {
        font-size: 16px;
        // background-color: transparent;
        // color: var(--color-headlines);
        border: none;
        // padding: 0;

        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    .lang-select-wrap {
        display: flex;
        justify-content: flex-end;
    }
}
