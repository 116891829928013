.fake-dropdown {
    position: relative;
    &:hover {
      cursor: pointer;
    }
  }
  .arrow-fake-dropdown {
    position: absolute;
    right: 6px;
    top: 13px;
    border: solid #495057 !important;
    border-width: 0 2px 2px 0 !important;
    padding-top: 2px !important;
    padding-bottom: 3px !important;
    padding-right: 2px !important;
    padding-left: 3px !important;
  
    &.up {
      top: 17px;
    }
  }

  /* ReactTags -  Styles for suggestions */
.ccsuggestions {
  position: absolute;
}
.ccsuggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ccsuggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ccsuggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ccsuggestions ul li.ccactiveSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ccremove {
  border: none;
  cursor: pointer;
  background: none;
  // color: white;
}
