@import "../../assets/styles/variables.scss";
@import "../../assets/styles/mixins.scss";

.jumbo-dash {
  @include jumbo-cover("/assets/img/jumbo-dashboard.png");

  h1 {
    color: $color-headlines;
  }
}

.statistics-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;

  .statistics-title {
    font-weight: bold;
    font-size: 20px;
  }

  .statistics-count-card {
    display: flex;
    flex-direction: column;
    border: 1px solid $color-borders;
    border-radius: $border-radius;
    width: 220px;
    height: 180px;
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;

    .statistics-count {
      font-weight: bold;
      color: $color-developers;
      font-size: 52px;
    }

    .statistics-views-label {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 8px;
    }
  }
  .statistics-chart-container {
    width: 800px;
    height: 350px;
    border: 1px solid $color-borders;
    border-radius: $border-radius;
    padding-top: 12px;
    padding-bottom: 12px;

    .statistics-chart-labels {
      display: flex;
      flex-direction: column;
      align-items: center;

      .statistics-chart-title {
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        margin-bottom: 15px;
      }

      .statistics-chart-since {
        border: 1px solid $color-borders;
        border-radius: 10px;
        padding-bottom: 2px;
        padding-top: 2px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 12px;
        max-width: 60%;
      }

      .statistics-chart-last {
        border: 1px solid $color-borders;
        border-radius: 10px;
        padding-bottom: 2px;
        padding-top: 2px;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 12px;
        max-width: 60%;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .dashboard-bannertitle {
    font-size: 36px !important;
  }
}

@media screen and (max-width: 768px) {
  .dashboard-bannertitle {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 576px) {
  .dashboard-bannertitle {
    padding-top: 50px;
    padding-bottom: 5px;
    font-size: 25px !important;
    margin-bottom: 15px;
  }
}
