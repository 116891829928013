@import '../../assets/styles/variables';


/* Button used to open the chat form - fixed at the bottom of the page */
.bottom-fixed-wrap {
    position: fixed;
    bottom: 0px;
    width: 100%;
    display: flex;
}

.open-button {
    background-color: $color-headlines;
    color: white;
    border: none;
    cursor: pointer;
    // position: fixed;
    // bottom: 0px;
    // right: 28px;
    margin: 0 auto;
    width: 255px;
    z-index: 8;
    font-size: 14px;
    font-weight: bold;
    height: 41px;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

/* The popup chat - hidden by default */
.chat-popup {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: none;
    // position: fixed;
    // bottom: 0;
    // right: 28px;
    margin: 0 auto;
    border: 1px solid $color-borders;
    z-index: 9;
}

.chat-popup-header {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #131f3d;
    color: white;
    border: none;
    cursor: pointer;
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.messaging-task {
    font-size: 12px;
    padding-left: 9px;
    border-bottom: 1px solid $color-borders;
    padding-bottom: 6px;
}


/* Add styles to the form container */
.form-container {
    width: 255px;
    padding-top: 8px;
    background-color: white;
}

/* Full-width textarea */
.form-container textarea {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    border: none;
    background: #f1f1f1;
    resize: none;
    min-height: 200px;
}

.user-row {
    padding: 12px 8px 12px 8px;

    &:hover {
        cursor: pointer;
        background-color: $color-lighthover;
    }
}

/* When the textarea gets focus, do something */
.form-container textarea:focus {
    background-color: #ddd;
    outline: none;
}

/* Set a style for the submit/send button */
.form-container .btn {
    background-color: #4CAF50;
    color: white;
    padding: 16px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom:10px;
    opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
    background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover, .open-button:hover {
    opacity: 1;
}

.user-name {
    margin-left: 10px;
    font-size: 14px;
}

.user-row {
    display: flex;
    align-items: center;
}

.chat-thumb {
    width: 54px;
    height: 54px;
    border-radius: 50%;
}



/* CHAT BOX */

.chat-box {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    display: none;
    // position: fixed;
    // bottom: 0;
    // right: 320px;
    margin: 0 auto;
    border: 1px solid $color-borders;
    z-index: 9;
    width: 255px;
    min-height: 333px;
    background-color: white;
    position: relative;

    .chatbox-header {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 6px 12px;
        background-color: $color-developers;
        height: 40px;
        color: white;
        font-size: 14px;
        line-height: 16px;
        position: relative;

        :last-child {
            font-size: 12px;
            line-height: 12px;
        }
    }

    .chat-log {
        font-size: 14px;
        padding: 6px 10px;
        display: flex;
        flex-direction: column-reverse;
    }

    textarea {
        width: 100%;
        border: none;
        background: transparent;
        resize: none;
        min-height: 40px;
        font-size: 14px;
        min-height: 20px;
        font-size: 14px;
        padding-top: 20px;
        padding-left: 10px;

        &:focus {
            outline: none;
        }
    }

    .chatbox-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        bottom: 0;
        position: absolute;
        width: 100%;
        padding-right: 10px;
        height: 40px;
        border-top: 1px solid $color-borders;

        img:hover {
            cursor: pointer;
        }
    }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .open-button {
        position: fixed;
        bottom: 0px;
        right: 28px;
    }
    .chat-popup {
        position: fixed;
        bottom: 0;
        right: 28px;
    }
    .chat-box {
        position: fixed;
        bottom: 0;
        right: 320px;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}